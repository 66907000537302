<template>
  <v-container>
    <v-btn v-if="readyToPrint" class="no-print ma-2" @click="print"
      >STAMPA ETICHETTE</v-btn
    >
    <v-btn v-if="readyToPrint" class="no-print ma-2" @click="downloadCSV"
      >SCARICA SQL Insert</v-btn
    >
    <v-btn v-if="readyToPrint" class="no-print ma-2" @click="regenerate"
      >RIGENERA ETICHETTE</v-btn
    >
    <div class="a4page">
      <div v-for="label in labels" :key="label.ssuuid" class="print_label">
        <Label :ssuuid="label.ssuuid" :qr_path="label.qr_svg_path"></Label>
      </div>
    </div>
  </v-container>
</template>

<script>
import QRCode from "qrcode";
import Convert from "xml-js";
const short = require("short-uuid");

import Label from "../../components/ricognizione/Label.vue";

export default {
  name: "PrintLabel",

  components: {
    Label,
  },
  props: {},

  data() {
    return {
      labels: [],
      
      // Let create a csv to export the generated label
      sql_insert_new_labels_printed: '',


      readyToPrint: false,
    };
  },

  methods: {
    print() {
      window.print();

      console.log(this.labels);
    },

    downloadCSV() {

      let link = document.createElement("a");
      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(this.sql_insert_new_labels_printed); 
      link.download = 'insert_70_new_labels.sql';
      link.click();
    },

    // Regenerate a new bunch of labels to print (70)
    async regenerate() {
      // Reset the previous generated labels
      this.labels = [];
      this.readyToPrint = false;
      await this.GenerateLabels();
      this.readyToPrint = true;
    },

    async GenerateQR(content) {
      let opts = {
        errorCorrectionLevel: "H",
        margin: 0,
        type: "svg",
      };

      let svg = await QRCode.toString(content, opts);
      let svgObj = Convert.xml2js(svg, { compact: true, spaces: 4 });

      //console.log(svgObj.svg.path[1]._attributes.d);
      let qrcode_path = svgObj.svg.path[1]._attributes.d;

      //return svg;
      return qrcode_path;
    },

    async GenerateLabels() {
      let asset_short_uuid = "";

      const decimalTranslator = short("23456789ABCDEF");

      // Number of new unique label to generate
      let numberOfLabel = 70;
      let validCodeGenerated = 0;

      // Reset the csv
        this.sql_insert_new_labels_printed = '';

      while (validCodeGenerated < numberOfLabel) {

        
        // Generate a uuid and take only the first 24 char
        asset_short_uuid = decimalTranslator.generate().slice(0, 24);
        
        // Test with an existing label
        //asset_short_uuid = 'E9B39F6B97AD34CCD9D6E7F4'
        //asset_short_uuid = 'A9B39F6B97AD34CCD9D6E7F5'

        // Check if the code exist into the db
        await this.$http
          .get( process.env.VUE_APP_PATRIMONIO_API + "/assets/label/" + asset_short_uuid, {
              headers: {
                authorization: "Bearer " + this.$store.state.authJwt,
              },
            }
          )
          .then(async (response) => {
            // Check if new code do not exisrt db side and here in the previous generated 
            if(!response.data.shortExists && !this.sql_insert_new_labels_printed.includes(asset_short_uuid)){
              console.log("Valid asset label not present in DB, Safe to create and add to DB!");
              // TODO: Add to DB        
              let qr_path = await this.GenerateQR(asset_short_uuid);
              // take last 6 char of the short uuid to show on the label
              let ssuuid = asset_short_uuid.slice(-6);
              console.log(ssuuid);
              this.labels.push({ ssuuid: ssuuid, qr_svg_path: qr_path });

              this.sql_insert_new_labels_printed +=  `INSERT INTO \`Labels\` (\`id\`, \`longCode\`, \`shortCode\`, \`used\`) VALUES (NULL, '${asset_short_uuid}', '${ssuuid}', '0'); \n`;
              // Let's generate the next lable till reach 70
              validCodeGenerated++;
            }
            else{
              console.log("Asset label short part already present in DB, I'm going to create a new label!");
            }
          })
          .catch((err) => {
            console.log(err);
          });

        
      }
    },
  },

  created: async function () {
    await this.GenerateLabels();
    this.readyToPrint = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  /* Your styles here */
  .no-print {
    display: none;
  }

  .a4page {
    padding-top: 11mm !important;
    padding-left: 7mm !important;
  }
  /*
    CALIBRAZIONE ESEGUITA SU STAMPANTE KYOCERA Taskalpha 4012i Secondo piano.
    In fase di stampa necessario regolare:
    
    - Page setup two-sided -> One-sided
    - Scale -> 100%
    - Page setup paper type -> Label
    - Page setup paper source (cassetto da dove pesca la carta) -> bypass tray
    - Image quality resolution -> 1200 dpi

  */
  .print_label {
    height: 25mm; /* circular label size */
    width: 25mm; /* circular label size */
    margin-top: 2mm;
    margin-left: 2mm;
    outline: 0pt solid;
    float: left;
  }
}
@page {
  size: A4;
  margin: 0;
}

@media screen {
  .a4page {
    width: 210mm;
    height: 297mm;
    padding-top: 13mm !important; /* the real value is 14.5mm but to show it centered into the canvas page is set to 13*/
    padding-left: 9mm !important; /* the real value is 11.5mm but to show it centered into the canvas page is set to 9*/
    outline: 1px dotted;
  }
  .print_label {
    height: 25mm; /* circular label size */
    width: 25mm; /* circular label size */
    margin-top: 2mm;
    margin-left: 2mm;
    outline: 0pt solid;
    float: left;
  }
}

/* 
.print_label {
  padding-top: 10mm !important;
  padding-left: 3mm !important;
  margin-top: 2.0mm;
  margin-left: 2.0mm;
  outline: 0pt solid;
  border-radius: 12.5mm; 
  float: left;
  font-size: 5mm;
} 
*/
</style>
