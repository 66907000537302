<template>
    <svg width="25mm" height="25mm" version="1.1" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        
        <!-- QR CODE -->
        <g transform="matrix(.4 0 0 .4 6.2 6.2)" style="shape-rendering:crispEdges">
        <path d="m0 0h35v35h-35z" fill="#fff"/>
        <path :d="qr_path" stroke="#000"/>
        </g>

        <!-- UNIMI TITLE -->
        <circle id="path83568" cx="12.5" cy="12.5" r="12.3" style="fill: none; stroke-width: 0.0; stroke: #000000"/>
        <text
        transform="matrix(-.56379 .48948 -.48948 -.56379 25.2 12.5)"
        style="fill: #000000; font-family: sans-serif; font-size: 3.175px; line-height: 1.25; stroke-width: 0.52917;"
        xml:space="preserve"
        >
        <textPath style="font-family: 'Times New Roman'; font-size: 3.1px" xlink:href="#path83568">
            UNIVERSITA' DEGLI STUDI DI MILANO
        </textPath>
        </text>

        <!-- LAST SIX DIGIT OF THE SSUUID -->
        <text 
        x="6.2" y="20.5"
        style="fill: #000000; font-family: sans-serif; font-size: 3.0px; line-height: 1.25; stroke-width: 0.26458;"
        xml:space="preserve"
        >
        <tspan
            x="6.2" y="20.5"
            style="font-family: 'Times New Roman'; font-size: 3.0px; stroke-width: 0.26458;"
        >
            {{ssuuid}}
        </tspan>
        </text>

        <!-- DIP NAME -->
        <text 
        x="8.5" y="22.5"
        style="fill: #000000; font-family: sans-serif; font-size: 1.4111px; line-height: 1.25; stroke-width: 0.26458;"
        xml:space="preserve"
        >
        <tspan
            x="8.5" y="22.5"
            style="font-family: 'Times New Roman'; font-size: 1.4111px; stroke-width: 0.26458;"
        >
            - DIP INFO -
        </tspan>
        </text>

    </svg>

</template>

<script>
// @ is an alias to /src

export default {
  name: "Label",

  props: {
    ssuuid: String,
    qr_path: String,
  },

  data() {
    return {
    };
  },
   methods: {

       
  },
};
</script>
